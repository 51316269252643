<template>
  <div class="" :style="styleWidth100">
    <!-- <p :style="border='1px solid black'">
      Votre entreprise est-elle exonérée de TVA ? Si oui, veuillez joindre le justificatif
    </p> -->
    <div :style="styleRaduisLine" v-for="category of rangList" :key="category.rang">
      <p v-if="category.rang === 'LOCAL'" class="p-m-1">
        (Si 51% de votre capital est détenu par des sénégalais et si 80% de votre personne/ d'encadrement est sénégalais
        et si 51% du reste de votre personnel est sénégalais alors cochez cette case Sinon cochez la dernière case)
      </p>
      <hr v-if="category.rang === 'LOCAL'"/>
      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center">
        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center" :style="styleWidth50">
          <RadioButton
            :class="{ 'p-invalid': !selectedRankFromList }"
            v-model="selectedRankFromList"
            :inputId="category.rang"
            name="category"
            @change="onChanged"
            :value="category.rang"
          />
          <label :for="category.rang" class="p-ml-2" :style="styleWidth90"><b>{{ category.adherant }}</b></label>
        </div>
        <span class="p-flex" :style="styleWidth50">
          <div>
            <span :for="category.rang">
              Votre redevance est de
              <span class="p-text-bold">
                {{
                  new Intl.NumberFormat('fr-FR', {
                    maximumSignificantDigits: 3,
                  }).format(category.montant_xof)
                }}
              </span>
              <span v-if="category.rang === 'RANK_DEUX' || category.rang === 'ETRANGER' || category.rang === 'LOCAL' || category.rang === 'DROIT_SENEGALAIS'"> Francs CFA TTC</span>
              <span v-else> Francs CFA HTVA</span>
            </span>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClassifyCompany',
  props: ['rangList', 'isValid', 'rang'],
  watch: {
    rang: function (v) {
      // console.log({ v });
      this.selectedRankFromList = v;
    },
  },
  data() {
    return {
      selectedRankFromList: null,
      styleRaduisLine: {
        border: '1px solid #d3d3d3',
        borderRadius: '.5rem',
        padding: '.4rem',
        margin: '.2rem',
        width: '100%',
      },
      styleWidth50: {
        width: '50%',
      },
      styleWidth100: {
        width: '100%',
      },
      styleWidth90: {
        width: '90%',
      },
    };
  },
  methods: {
    // USE IN PARENT COMPONENTS TEMPLATE
    // <classify-company @changed="getCompanyRank" />

    // USE IN PARENT COMPONENTS SCRIPTS
    // import ClassifyCompany from '@/components/v2/ClassifyCompany.vue';
    // getCompanyRank(e){
    //   alert(e);
    // },

    onChanged: function () {
      this.$emit('changed', this.selectedRankFromList);
    },
  },
};
</script>
