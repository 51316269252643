const FormValidationMixin = {
  methods: {
    getForm() {
      return this.v$.payload;
    },
    getField(name) {
      return this.v$.payload[name];
    },
    getModel(name) {
      return this.v$.payload[name].$model;
    },
    hasEmailError(name) {
      return this.getField(name).email?.$invalid;
    },
    hasRequiredError(name) {
      return this.getField(name).required?.$invalid;
    },
    hasMinLenghtError(name) {
      return this.getField(name).minLength?.$invalid;
    },
    hasMaxLengthError(name) {
      return this.getField(name).maxLength?.$invalid;
    },

    hasNumericError(name) {
      return this.getField(name).numeric?.$invalid;
    },

    hasUrlError(name) {
      return this.getField(name).url?.$invalid;
    },

    hasAnyErrors(name) {
      return [
        this.hasEmailError(name),
        this.hasRequiredError(name),
        this.hasMinLenghtError(name),
        this.hasMaxLengthError(name),
        this.hasNumericError(name),
        this.hasUrlError(name),
      ].reduce((avg, value) => avg || value, false);
    },
  },
};

export default FormValidationMixin;
