<template>
  <div class="p-grid">
    <Toast position="top-right" />
    <div class="p-col-12">
      <br />
      <div class="card">
        <h5>Mon Entreprise</h5>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <label for="name-id">Nom de l'entreprise <span class="p-text-bold text-red">*</span></label>
            <InputText :disabled="true" v-model="payload.name" id="name-id" type="text" />
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="email-id">E-mail de l'entreprise <span class="p-text-bold text-red">*</span></label>
            <InputText
              :class="{ 'p-invalid': hasAnyErrors('email') && submitted }"
              v-model="getField('email').$model"
              id="email-id"
              type="email"
            />
            ¨<small v-show="hasRequiredError('email') && submitted" style="color: red"
              >Veuillez renseigner l'e-mail de l’entreprise.</small
            >
            <small v-show="hasEmailError('email') && submitted" style="color: red"
              >Veuillez respecter le format du champ « E-mail de l’entreprise »</small
            >
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label>Date de création <span class="p-text-bold text-red">*</span></label>
            <Calendar
              :class="{ 'p-invalid': hasAnyErrors('companyCreatedAt') && submitted }"
              v-model="getField('companyCreatedAt').$model"
              placeholder="JJ/MM/AAAA"
              id="date-creation-id"
              dateFormat="dd/mm/yy"
              :showIcon="true"
              :maxDate="today"
            />
            <small v-show="hasRequiredError('companyCreatedAt') && submitted" style="color: red">
              Veuillez renseigner la date de création de l’entreprise.
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="adresse-id">Adresse <span class="p-text-bold text-red">*</span></label>
            <InputText
              :class="{ 'p-invalid': hasAnyErrors('address') && submitted }"
              v-model="getField('address').$model"
              id="adresse-id"
              type="text"
            />
            <small v-show="hasRequiredError('address') && submitted" style="color: red"
              >Veuillez renseigner le champ « Adresse ».</small
            >
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="adresse-id">Site internet</label>
            <InputText
              :class="{ 'p-invalid': hasAnyErrors('www') && submitted }"
              v-model="getField('www').$model"
              placeholder="www.exemple.com"
              id="adresse-id"
              type="text"
            />
            <small v-show="hasUrlError('www') && submitted" style="color: red">
              Veuillez respecter le format du champ « Site internet » (Exemples : www.site.com, http://site.com,
              https://site.com).
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <div class="p-d-flex">
              <label for="tax-id-number"
                >Numéro d'identification fiscal <span class="p-text-bold text-red">*</span></label
              >
              <div class="p-grid">
                <div class="p-col-12 tooltip--default">
                  <i class="pi pi-info-circle" v-tooltip.top="getTaxIdTooltipInfo()" />
                </div>
              </div>
            </div>
            <InputText
              :class="{ 'p-invalid': hasAnyErrors('identificationNumber') && submitted }"
              v-model="getField('identificationNumber').$model"
              id="tax-id-number"
              type="text"
            />
            <small v-show="hasRequiredError('identificationNumber') && submitted" style="color: red">
              Veuillez renseigner le champ « Numéro d'identification fiscal ».
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="pays-id">Pays <span class="p-text-bold text-red">*</span></label>
            <Dropdown
              :class="{ 'p-invalid': hasAnyErrors('nationId') && submitted }"
              v-model="getField('nationId').$model"
              placeholder="Sélectionner un pays"
              :options="countries"
              optionLabel="name"
              id="pays-id"
            ></Dropdown>
            <small v-show="hasRequiredError('nationId') && submitted" style="color: red"
              >Veuillez renseigner le champ.</small
            >
          </div>

          <div class="p-field p-col-12 p-md-6">
            <div class="p-d-flex">
              <label for="id-trade-register">Registre de commerce <span class="p-text-bold text-red">*</span></label>
              <div class="p-grid">
                <div class="p-col-12 tooltip--default">
                  <i class="pi pi-info-circle" v-tooltip.top="getCommercialRegisterTooltipInfo()" />
                </div>
              </div>
            </div>
            <InputText
              :class="{ 'p-invalid': hasAnyErrors('tradeRegister') && submitted }"
              v-model="getField('tradeRegister').$model"
              id="id-trade-register"
              type="text"
            />
            <small v-show="hasRequiredError('tradeRegister') && submitted" style="color: red">
              Veuillez renseigner le champ « Registre de commerce ».
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="domaines-metier-id">Domaine(s) métier(s) <span class="p-text-bold text-red">*</span></label>
            <MultiSelect
              :class="{ 'p-invalid': hasAnyErrors('domaineMetiersIds') && submitted }"
              v-model="getField('domaineMetiersIds').$model"
              class="multiselect-custom"
              optionLabel="libelle"
              :options="domaines"
              :filter="true"
              placeholder
            >
              <template #value="slotProps">
                <div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.id">
                  <div>{{ option.libelle }}</div>
                </div>
                <template v-if="!slotProps.value || slotProps.value.length === 0">
                  <div class="country-placeholder">Sélectioner un ou plusieurs domaines métiers</div>
                </template>
              </template>
            </MultiSelect>

            <small v-show="hasRequiredError('domaineMetiersIds') && submitted" style="color: red">
              Veuillez renseigner un ou plusieurs domaines métiers.
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <div class="p-field">
              <label for="fj">Forme juridique <span class="p-text-bold text-red">*</span></label>
              <Dropdown
                :class="{ 'p-invalid': hasAnyErrors('formeJuridiqueId') && submitted }"
                placeholder="Sélectionner une forme juridique"
                v-model="getField('formeJuridiqueId').$model"
                :options="formesJuridiques"
                id="forme-juridique-id"
                optionLabel="libelle"
              ></Dropdown>
              <small v-show="hasRequiredError('formeJuridiqueId') && submitted" style="color: red">
                Veuillez renseigner le champ « Forme juridique ».
              </small>
            </div>
          </div>

          <div class="p-col-12 p-d-flex">
            <div class="p-field" v-bind:style="{ width: '50%' }">
              <div class="p-field p-col-12">
                <upload-file-input
                  :controlLabel="'Logo de l\'entreprise'"
                  :inputLabel="'Choisir une fichier  '"
                  :accept="'.png,.jpeg,.jpg'"
                  :blob="multiparts.logo.blob"
                  :value="multiparts.logo.url"
                  :name="'logo'"
                  :marginLeft="'140px'"
                  @uploaded="handleUpload"
                  @canceled="handleCancelUpload"
                  @preview-shown="handleShowPreview"
                />
              </div>
              <div class="p-field p-col-12">
                <br />
                <label>Pièces Jointes <span class="p-text-bold text-red">*</span></label>
                <p class="p-text-italic">Formats acceptés : .jpeg, .png, .pdf</p>
                <small v-show="false && submitted" style="color: red">
                  Veuillez joindre une pièce jointe dans le champ « Document d’identification fiscale ».
                </small>
                <small v-show="false && submitted" style="color: red">
                  Veuillez joindre une pièce jointe dans le champ « Document Registre de commerce ».
                </small>
                <small v-show="false && submitted" style="color: red">
                  Veuillez joindre des pièces uniquement au format <b>pdf</b>, <b>jpeg</b> et <b>png</b>.
                </small>
                <small v-show="false && submitted" style="color: red">
                  Veuillez joindre une pièce jointe dans le champ « Document Statut de l'entreprise ».
                </small>

                <div class="p-field p-col-12">
                  <br />
                  <upload-file-input
                    :controlLabel="'Document d’identification fiscal'"
                    :inputLabel="'Choisir une fichier  '"
                    :accept="'.pdf,.png'"
                    :blob="multiparts.taxIdNumberFile.blob"
                    :name="'taxIdNumberFile'"
                    :value="multiparts.taxIdNumberFile.url"
                    :marginLeft="'140px'"
                    @uploaded="handleUpload"
                    @canceled="handleCancelUpload"
                    @preview-shown="handleShowPreview"
                  />
                </div>
                <div class="p-field p-col-12">
                  <upload-file-input
                    :controlLabel="'Registre de commerce'"
                    :inputLabel="'Choisir une fichier  '"
                    :accept="'.pdf,.png'"
                    :blob="multiparts.commercialRegisterFile.blob"
                    :name="'commercialRegisterFile'"
                    :value="multiparts.commercialRegisterFile.url"
                    :marginLeft="'130px'"
                    @uploaded="handleUpload"
                    @canceled="handleCancelUpload"
                    @preview-shown="handleShowPreview"
                  />
                </div>
                <div class="p-field p-col-12">
                  <upload-file-input
                    :controlLabel="'Statut de l\'entreprise'"
                    :inputLabel="'Choisir une fichier  '"
                    :accept="'.pdf,.png'"
                    :blob="multiparts.companyStatusFile.blob"
                    :value="multiparts.companyStatusFile.url"
                    :name="'companyStatusFile'"
                    :marginLeft="'130px'"
                    @uploaded="handleUpload"
                    @canceled="handleCancelUpload"
                    @preview-shown="handleShowPreview"
                  />
                </div>
              </div>
            </div>
            <div class="p-field p-ml-4" v-bind:style="{ width: '50%' }">
              <label for="fj"
                >Cochez la case qui correspond à votre entreprise (un seul choix est possible)
                <span class="p-text-bold text-red">*</span></label
              >
              <classify-company
                :rangList="tarifications"
                :rang="payload.rank"
                :isValid="isRankValid()"
                @changed="handleSetSubscriptionRank"
              />
              <small v-show="false" style="color: red">Veuillez répondre au questionnaire.</small>
            </div>
          </div>
        </div>
      </div>

      <Button
        v-bind:style="{ width: '20%' }"
        v-show="canPressHomeBtn()"
        @click="handleBackToHomePage"
        label="Retour"
        class="p-mr-2 p-mb-2"
      />
      <Button
        v-bind:style="{ width: '20%' }"
        v-show="canPressReturnBtn()"
        :loading="loaders[LoadersIds.Back]"
        @click="handlePreviousStep"
        label="Précédent"
        class="p-mr-2 p-mb-2"
      />
      <Button
        v-bind:style="{ width: '20%' }"
        v-show="canPressSubmitBtn()"
        :loading="loaders[LoadersIds.Submit]"
        @click="handleSubmit"
        label="Enregistrer les informations"
        class="p-mr-2 p-mb-2"
      />

      <Button
        v-show="canPressValidateBtn()"
        v-bind:style="{ width: '20%' }"
        @click="handleRequestForValidation"
        label="Soumettre l'inscription"
        class="p-mr-2 p-mb-2 p-button-success"
      />
    </div>
    <FileViewer
      v-if="null !== fileViewer.url"
      :url-link="fileViewer.url"
      :original-url="fileViewer.originalUrl"
      :title="fileViewer.title"
      v-on:close="onFileViewed"
    />
  </div>
</template>
<script>
import axios from 'axios';
import FileViewer from '@/components/FileViewer';
import ClassifyCompany from '@/components/classify-company';
import UploadFileInput from '@/components/upload-file-input';
import EnumFormatMixin from '@/mixins/enum-format';
import LoggedUserMixin from '@/mixins/logged-user';
import FormValidationMixin from '@/mixins/form-validation';
import { useVuelidate } from '@vuelidate/core';
import { required, email, numeric, url } from 'vuelidate/lib/validators';
import http from '../../services/http.service';
import Http from '../../services/http-v2.service';
import { BackendUrls } from '@/services/urlsConfigs';
import moment from 'moment';

export default {
  mixins: [EnumFormatMixin, LoggedUserMixin, FormValidationMixin],
  components: {
    FileViewer,
    ClassifyCompany,
    UploadFileInput,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loaders: [false, false, false, false],
      LoadersIds: {
        Home: 0,
        Back: 1,
        Submit: 2,
        Validate: 3,
      },
      fileViewer: {
        url: null,
        originalUrl: null,
        title: null,
      },
      formesJuridiques: [],
      tarifications: [],
      countries: [],
      domaines: [],
      loadings: [],
      today: new Date(),
      loggedUser: null,
      currentSubscription: null,
      submitted: false,
      payload: {
        identificationNumber: null,
        companyCreatedAt: null,
        formeJuridiqueId: null,
        domaineMetiersIds: [],
        tradeRegister: null,
        nationId: null,
        address: null,
        email: null,
        phone: null,
        name: null,
        www: null,
        rank: null,
      },
      multiparts: {
        logo: {
          blob: null,
          isLoaded: false,
          url: null,
        },
        taxIdNumberFile: {
          blob: null,
          isLoaded: false,
          url: null,
        },
        commercialRegisterFile: {
          blob: null,
          isLoaded: false,
          url: null,
        },
        companyStatusFile: {
          blob: null,
          isLoaded: false,
          url: null,
        },
      },
    };
  },
  validations: {
    payload: {
      name: {
        required,
      },
      address: {
        required,
      },
      identificationNumber: {
        required,
      },
      tradeRegister: {
        required,
      },
      companyCreatedAt: {
        required,
      },
      nationId: {
        required,
      },
      formeJuridiqueId: {
        required,
      },
      domaineMetiersIds: {
        required,
      },
      email: {
        required,
        email,
      },
      www: {
        url: function (_, value) {
          const pattern = /(^(http(s)?:\/\/)|^(www\.))([a-zA-Z-]+)(\.[a-z]{2,3})$/gim;
          return value.www !== null && value.www != '' ? pattern.test(value.www) : true;
        },
      },
      phone: {
        numeric,
      },
    },
  },
  async mounted() {
    this.token = localStorage.getItem('token');
    this.config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    if (localStorage.token) {
      this.getProfil();
      this.getDomaines();
      this.getNations();
      this.getFormJuridique();
      this.getTarifications();
    } else {
      this.$router.push('/login');
    }
  },

  methods: {
    canShowLogoUploadBtn() {
      return this.multiparts.logo.blob === null;
    },

    canPressHomeBtn() {
      return true;
    },

    canPressReturnBtn() {
      return true;
    },

    canPressSubmitBtn() {
      return (
        this.isFillingUserForm(this.currentSubscription) ||
        this.isFillingEnterpriseForm(this.currentSubscription) ||
        this.isSubscriptionRejected(this.currentSubscription)
      );
    },

    canPressValidateBtn() {
      return (
        this.isFillingEnterpriseForm(this.currentSubscription) || this.isSubscriptionRejected(this.currentSubscription)
      );
    },

    isRankValid() {
      return this.payload.rank != '' && this.payload.rank != null && this.payload.rank != undefined && this.submitted;
    },

    checkPoint() {
      let error_msg = 'Veuillez joindre ';
      this.submitted = true;

      if (this.getForm().$invalid && this.isRankValid()) return false;

      if (!this.multiparts.taxIdNumberFile.blob && !this.multiparts.taxIdNumberFile.url) {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur lors de la sauvegarde .',
          detail: error_msg + "le document d'identification fiscal de votre entreprise.",
          life: 7000,
        });
        return false;
      }

      if (!this.multiparts.commercialRegisterFile.blob && !this.multiparts.commercialRegisterFile.url) {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur lors de la sauvegarde .',
          detail: error_msg + 'le registre de commerce de votre entreprise.',
          life: 7000,
        });
        return false;
      }

      if (!this.multiparts.companyStatusFile.blob && !this.multiparts.companyStatusFile.url) {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur lors de la sauvegarde .',
          detail: error_msg + 'les statuts de votre entreprise.',
          life: 7000,
        });
        return false;
      }

      return true;
    },

    getTaxIdTooltipInfo() {
      return "Identification fiscale : L’identification fiscale est le numéro d'identification attribué par les administrations fiscales lors de la création de votre entreprise. Par exemple, au Sénégal le numéro est composé de 7 caractères numériques et d’un suffixe de 3 caractères alphanumériques (ex : 0001234 2G3). En France, le numéro est composé de 9 chiffres pour le SIREN et pour le SIRET, les 9 chiffres du SIREN suivi de 5 chiffres propres à l’’établissement. En Angleterre, le numéro correspond au NIF et il est composé de 9 chiffres.";
    },

    getCommercialRegisterTooltipInfo() {
      return "'Registre de commerce : Le registre du commerce et des sociétés répertorie toutes les entreprises commerciales. Par exemple, au Sénégal, cela correspond au NINEA qui est composé de 7 caractères numériques (ex : 0001234). En France, le numéro est composé de la mention RCS, la ville d’immatriculation, une lettre A pour les commerçants et B pour les sociétés et le numéro SIREN de l’entreprise à 9 chiffres. En Angleterre, le numéro est composé de 8 chiffres ou 2 lettres suivies de 6 chiffres (ex : 01234567 ou LP123456).'";
    },

    async getProfil() {
      await http
        .get('/users/getProfile', {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          this.loggedUser = response.data;
          const enterprise = this.loggedUser.entreprise;

          this.payload.identificationNumber = enterprise.identificationNumber || null;
          this.payload.companyCreatedAt = enterprise.companyCreatedAt || null;
          this.payload.formeJuridiqueId = enterprise.formeJuridique || null;
          this.payload.domaineMetiersIds = enterprise.domaineMetiers || [];
          this.payload.tradeRegister = enterprise.tradeRegister || null;
          this.payload.nationId = enterprise.pays || null;
          this.payload.address = enterprise.address || null;
          this.payload.email = enterprise.email || null;
          this.payload.phone = enterprise.phone || null;
          this.payload.name = enterprise.name || null;
          this.payload.www = enterprise.www || null;
          this.payload.rank = enterprise.rangSouscriptionEnCours || null;

          this.multiparts.logo.url = enterprise.logo;
          this.multiparts.commercialRegisterFile.url = enterprise.registreCommerce;
          this.multiparts.taxIdNumberFile.url = enterprise.numeroIdentification;
          this.multiparts.companyStatusFile.url = enterprise.statusEntreprise;
          this.currentSubscription = this.getCurrentSubscription(this.loggedUser);
        });
    },

    async getDomaines() {
      await http.get('/domaines-metiers?limit=100&sort=libelle,asc', this.config).then((res) => {
        this.domaines = res.data.data;
      });
    },

    async getNations() {
      await http.get('/countries?limit=300&sort=name&sort=name,asc', this.config).then((res) => {
        this.countries = res.data.data;
      });
    },

    async getFormJuridique() {
      await http.get('/formesJuridiques?limit=100', this.config).then((res) => {
        this.formesJuridiques = res.data.data;
      });
    },

    async getTarifications() {
      await http.get('/tarifs?limit=100?sort=montant_xof,asc', this.config).then((res) => {
        this.tarifications = res.data;
        this.tarifications.sort((a, b) => b.montant_xof - a.montant_xof);
      });
    },

    onFileViewed() {
      this.fileViewer.url = null;
      this.fileViewer.title = null;
      this.fileViewer.originalUrl = null;
    },

    onFileOpen(url, title) {
      url = url.includes('localhost') ? url : 'https' + url.substr(4);
      const token = localStorage.getItem('token');
      this.fileViewer.title = title;

      http
        .get(url, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        })
        .then(this.handleFileOpenSucceed);
    },

    handleFileOpenSucceed(response) {
      const blobType = url.substr(-4);
      const blob = new Blob([response.data], {
        type: blobType === '.pdf' ? 'application/pdf' : 'image/png',
      });
      this.fileViewer.url = URL.createObjectURL(blob);
      this.fileViewer.originalUrl = url;
    },

    handleCancelUpload(event) {
      const name = event.name;
      this.multiparts[name].blob = null;
      this.multiparts[name].isLoaded = false;
      this.multiparts[name].url = null;
    },

    handleUpload(event) {
      const binary = event.binary.target.files[0];
      const name = event.name;
      this.multiparts[name].blob = binary;
      this.multiparts[name].isLoaded = true;
    },

    // TODO: To Refactor, it's everywhere.
    handleShowPreview(event) {
      let { url, title, blobType } = event;
      const isLocalUrl = url.includes('localhost') || /^blob/.test(url);
      url = isLocalUrl ? url : 'https' + url.substr(4);

      axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: blobType });
          this.fileViewer.url = URL.createObjectURL(blob);
          this.fileViewer.originalUrl = url;
          this.fileViewer.title = title;
        })
        .catch(console.error);
    },

    handleSetSubscriptionRank(event) {
      this.payload.rank = event;
    },

    handleBackToHomePage() {
      this.$router.push('/');
    },

    handlePreviousStep() {
      this.loaders[this.LoadersIds.Back] = true;
      this.$router.push('/menu');
    },

    handleSubmit() {
      const canSubmit = this.checkPoint();

      if (!canSubmit) return;
      this.loaders[this.LoadersIds.Submit] = true;

      const payload = { ...this.payload };
      payload.companyCreatedAt = moment(payload.companyCreatedAt).format('YYYY-MM-DD');
      payload.domaineMetiersIds = payload.domaineMetiersIds.map((domain) => domain.id);
      payload.formeJuridiqueId = payload.formeJuridiqueId?.id;
      payload.nationId = payload.nationId?.id;

      const params = Object.keys(payload).reduce((hash, key) => hash + `${key}=${payload[key] || ''}&`, '?');
      const form = new FormData();
      if (this.multiparts.logo.blob && !this.multiparts.logo.url) form.append('logo', this.multiparts.logo.blob); //TODO
      form.append('taxIdNumberFile', this.multiparts.taxIdNumberFile.blob);
      form.append('commercialRegisterFile', this.multiparts.commercialRegisterFile.blob);
      form.append('companyStatusFile', this.multiparts.companyStatusFile.blob);

      const baseUrl = BackendUrls.SubscriptionUpdateEnterpriseUrl();
      return Http.post(`${baseUrl}${params}`, form, { 'Content-Type': 'multipart/form-data' })
        .then(this.handleSuccessSubmit)
        .catch(this.handleFailedSubmit);
    },

    handleSuccessSubmit() {
      this.loaders[this.LoadersIds.Submit] = false;
      this.loaders[this.LoadersIds.Back] = false;
      this.getProfil();
      this.$toast.add({
        severity: 'success',
        summary: 'Accomplie.',
        detail: 'Les informations ont été enregistées avec succès.',
        life: 7000,
      });
    },

    handleFailedSubmit(error) {
      let prefix_error_msg = 'Veuillez renseigner ';
      const title_error_box = 'Echec de la sauvegarde des données.';
      this.loaders[this.LoadersIds.Submit] = false;
      this.loaders[this.LoadersIds.Back] = false;

      switch (error?.response?.data?.message) {
        case 'formeJuridiqueId parameter is missing':
          this.$toast.add({
            severity: 'error',
            summary: title_error_box,
            detail: prefix_error_msg + "le champ 'Forme juridique'",
            life: 7000,
          });
          break;
        case "The parameter 'companyCreatedAt' of value 'Invalid date' could not be converted to type 'Date'":
          this.$toast.add({
            severity: 'error',
            summary: title_error_box,
            detail: prefix_error_msg + "la date de création de l'entreprise",
            life: 7000,
          });
          break;
        case 'nationId parameter is missing':
          this.$toast.add({
            severity: 'error',
            summary: title_error_box,
            detail: prefix_error_msg + 'le pays',
            life: 7000,
          });
          break;
        case 'rank parameter is missing':
          this.$toast.add({
            severity: 'error',
            summary: title_error_box,
            detail: prefix_error_msg + 'le rang de votre entreprise',
            life: 7000,
          });
          break;

        default:
          this.$toast.add({
            severity: 'error',
            summary: title_error_box,
            detail:
              error?.response?.data?.message ||
              'Une erreur est survenue, veuillez recommencer ou contacter votre administrateur.',
            life: 7000,
          });
          break;
      }
    },

    handleRequestForValidation() {
      const canSubmit = this.checkPoint();
      if (!canSubmit) return;

      this.handleSubmit().then(() => {
        this.loaders[this.LoadersIds.Validate] = true;
        Http.get(BackendUrls.SubscriptionValidationRequestUrl(this.currentSubscription.id))
          .then(this.handleValidationRequestSucceed)
          .catch(this.handleValidationRequestFailed);
      });
    },

    handleValidationRequestSucceed() {
      this.loaders[this.LoadersIds.Submit] = false;
      this.loaders[this.LoadersIds.Back] = false;
      this.$toast.add({
        severity: 'success',
        summary: 'Accomplie.',
        detail: 'Les informations ont été enregistées avec succès.',
        life: 7000,
      });
      this.$router.push('/');
    },

    handleValidationRequestFailed: (error) => {
      this.loaders[this.LoadersIds.Submit] = false;
      this.loaders[this.LoadersIds.Back] = false;
      this.$toast.add({
        severity: 'error',
        summary: 'Echec de la sauvegarde des données.',
        detail:
          error?.response?.data?.message ||
          'Une erreur est survenue, veuillez recommencer ou contacter votre administrateur.',
        life: 7000,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.imagePreviewWrapper {
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #00245a;
}
.text-red {
  color: #db3c30;
}

.tooltip--default {
  margin-left: 15px;
  height: 29px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>
