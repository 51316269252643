const EnumFormatMixin = {
  data: function() {
    return {
      enumMap: {
        AMI: "Appel à manifestation d'intérêt",
        AO: "Appel d'offre",
        ATTRIBUE: "Attribué",
        APPROUVE: "Approuvé",
        CARTE_BANCAIRE: "Carte bancaire ou Paiement mobile",
        CHEQUE: "Chèque",
        CREE: "Créé",
        DONNEUR_ORDRE: "Donneur d'ordre",
        DROIT_SENEGALAIS: "Prestataire de droit sénégalais",
        DRP: "Demande de renseignements de prix",
        ECHU: "Échu",
        EN_COURS: "En cours",
        ETRANGER: "Prestataire étranger",
        EXCLUSIF: "Exclusif",
        FERME: "Fermé",
        LIBRE: "Libre",
        LOCAL: "Prestataire local",
        MAJOR: "Opérateur (Titulaire d'un titre minier)",
        MIXTE: "Mixte",
        NON_APPROUVE: "Renvoyé",
        NON_EXCLUSIF: "Non exclusif",
        OUVERT: "Ouvert",
        POSTE: "Poste",
        PRESTATAIRE: "Prestataire",
        RANK_UN: "Sous-traitant de rang un (1)",
        RANK_DEUX: "Sous-traitant de rang deux (2)",
        REJETE: "Rejeté",
        RENVOYE: "Renvoyé",
        SOUS_TRAITANT: "Sous traitant",
        SOUMIS: "Transmis",
        TRANSMIS: "Transmis",
        VALIDE: "Validé",
        VIREMENT: "Virement",
        // LOCAL CONTENT STATUS ENUMS
        F_CREATED: "Plan créé",
        F_SUBMITTED: "Plan soumis",
        F_REJECTED: "Plan à corriger",
        F_VALIDATED: "Plan validé",
        R_CREATED: "Rapport créé",
        R_SUBMITTED: "Rapport soumis",
        R_REJECTED: "Rapport à corriger",
        R_VALIDATED: "Rapport validé",
        // --------------
      }
    }
  }
}

export default EnumFormatMixin;