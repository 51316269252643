<template>
  <Toast position="top-right" />
  <div class="p-inputgroup">
    <label for="statutCompany"
      >{{ controlLabel }} <span v-show="isRequired" class="p-text-bold text-red">*</span> :</label
    >
    <span class="p-inputgroup-addon" :style="{ 'margin-left': marginLeft }">
      <i class="pi pi-paperclip"></i>&nbsp;&nbsp;&nbsp;
      <input
        ref="fileUploadElement"
        v-show="!isBlobUploaded()"
        :label="inputLabel"
        :accept="accept"
        @change="onUpload"
        type="file"
      />
      <button v-show="canShowPreview()" @click="onShowPreview" class="max-w-20">Voir le fichier</button>
      <button v-show="canCancelUpload()" @click="onUploadCancel">Annuler</button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'UploadFileInput',
  props: ['controlLabel', 'inputLabel', 'accept', 'name', 'value', 'blob', 'isRequired', 'marginLeft'],
  methods: {
    isBlobUploaded() {
      return this.blob !== null || this.isValueNotNull();
    },
    canCancelUpload() {
      return this.isBlobUploaded();
    },
    canShowPreview() {
      return this.isBlobUploaded() || this.isValueNotNull();
    },
    isValueNotNull() {
      return this.value != null && this.value != undefined && this.value != '';
    },

    onUpload(event) {
      const [binary] = event.target.files;
      const extension = binary.name.substr(-4);
      if (!this.accept?.includes(extension)) {
        this.$refs.fileUploadElement.value = null;
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail: `Veuillez uploadé un fichier adéquat. (${this.accept}).`,
          life: 7000,
        });
        return;
      }
      this.$emit('uploaded', { binary: event, name: this.name });
    },
    onUploadCancel() {
      this.$emit('canceled', { name: this.name });
    },
    onShowPreview() {
      const url = this.value ? this.value : URL.createObjectURL(this.blob);
      console.log('onShowPreview ------> ', { url, value: this.vaue, blob: this.blob });
      const extension = this.value ? this.value.substr(-4) : this.blob.name.substr(-4);
      const blobType = extension === '.pdf' ? 'application/pdf' : 'image/png';
      this.$emit('preview-shown', { url, title: this.controlLabel, blobType });
    },
  },
};
</script>

<style>
.max-w-20 {
  max-width: 20em;
}
</style>
