<template>
  <div class="cnscl-file-viewer">
    <div class="viewer__content">
      <h5>
        {{ title }}
        <span class="pi pi-times-circle" style="float: right; font-size: 20px" @click="onClose()"></span>
      </h5>
      <hr />
      <img id="img-id" :src="urlLink" v-if="isImage(originalUrl)" />
      <iframe :src="urlLink" width="100%" height="500px" v-else />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: String,
    urlLink: String,
    originalUrl: String,
  },
  methods: {
    isImage() {
      return ['.png', '.jpg', '.jpeg', '.gif'].reduce((bool, ext) => bool || this.originalUrl?.includes(ext), false);
    },

    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.cnscl-file-viewer {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.viewer__content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
}
#img-id {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
